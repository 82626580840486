import axios from 'axios';
import { showToast } from 'module/Common/Toast/toast';

export const fetchSalesTypePrice = (
  item_display_name,
  formData,
  itemId,
  type,
  item_id,
  setTableData,
  setKitFullDetails,
  setItems,
  setDataProcessing,
  Translate,
  description
) => {
  if (itemId && formData.sales_type_id) {
    axios
      .get(`inventory/sales-type-price/${formData.sales_type_id}/${itemId}`, {
        params: {
          type: type,
          warehouse_id: formData.warehouse_id
        }
      })
      .then(response => {
        if (response.data.success) {
          if (response.data.data.is_kit == 1) {
            axios
              .get('inventory/stock-kits/' + item_id)
              .then(response => {
                if (response.data.success) {
                  setKitFullDetails(prev => [...prev, response.data.data]);
                  setItems(response.data.data.kit_items);
                } else {
                  showToast(
                    Translate(
                      'Something went wrong, please refresh the page and try again'
                    ),
                    'error'
                  );
                }
              })
              .catch(error => {
                console.log(error);
                showToast(
                  Translate(
                    'Something went wrong, please refresh the page and try again'
                  ),
                  'error'
                );
              });
          }
          setTableData({
            item_display_name:
              item_display_name ?? response.data.data.item_name,
            item_display_name_ref: {
              label: response.data.data.item_name,
              value: response.data.data.stock_id
            },
            item_description:
              response?.data?.data?.item_description?.length > 0
                ? response.data.data.item_description
                : description?.length > 0
                ? description
                : '',
            stock_id: itemId,
            stock_id_ref: itemId,
            kit_id: response?.data?.data?.is_kit ? itemId : '',
            unit_price: response.data.data.price,
            sales_type_id: response.data.data.sales_type,
            unit_name: response.data.data.unit_name,
            unit_tax: '0',
            line_total: '0',
            qoh: response.data.data.qoh,
            is_kit: response.data.data.is_kit ? '1' : '0',
            quantity: 1,
            remove: response.data.data.is_kit ? true : false,
            trans_date: formData.trans_date,
            has_batch_inventory: response?.data?.data?.is_kit
              ? 0
              : response?.data?.data?.has_batch_inventory
              ? 1
              : 0,
            batch_number: '',
            batch_number_ref: '',
            actual_price: response.data.data.price,
            customer_commission_percentage:
              response?.data?.data?.customer_commission_percentage
          });
          setDataProcessing(false);
        } else {
          showToast(
            Translate(
              'Something went wrong, please refresh the page and try again'
            ),
            'error'
          );
          setDataProcessing(false);
        }
      })
      .catch(error => {
        console.log(error);
        setTableData({
          item_display_name: '',
          item_display_name_ref: '',
          item_description: '',
          stock_id: '',
          unit_price: '',
          qoh: '',
          unit_name: ''
        });
        setDataProcessing(false);
      });
  } else {
    if (!formData.warehouse_id) {
      showToast(Translate('Please select warehouse'), 'error');
    } else {
      showToast(Translate('Please select sales type'), 'error');
    }
    setDataProcessing(false);
  }
};
