import { React, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Row
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import { MdOutlinePayments } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { StoreContext, AuthWizardContext } from 'context/Context';
import { showToast } from 'module/Common/Toast/toast';
import { CheckZeroOrLessThanZeroValidator } from 'module/Common/Validators/CheckZeroOrLessThanZero';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import SelectBankAccount from 'components/form/SelectBankAccount';
import VoucherDetail from 'components/voucher-details/VoucherDetail';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import useAcodaxNumberFormat from 'hooks/useAcodaxNumberFormat';

function PaymentScreenPopup({
  show,
  person,
  invoiceData,
  onHide,
  fetchData,
  dueAmount,
  onPaymentSuccess
}) {
  const Translate = useAxisproTranslate();
  const acodaxNumberFormat = useAcodaxNumberFormat();
  const { store } = useContext(StoreContext);
  const { user } = useContext(AuthWizardContext);
  let defaultBankAccount = user?.branch ?? '';
  let url = '';
  const [formData, setFormData] = useState({
    amount: dueAmount,
    discount_amount: 0,
    cheque_number: '',
    cheque_date: ''
  });
  const [formError, setFormError] = useState({});
  const [saving, setSaving] = useState(false);
  const [givenAmount, setGivenAmount] = useState('');
  const [change, setChange] = useState('');
  const [voucherId, setVoucherId] = useState();
  const [showVoucherDetailArea, setShowVoucherDetailArea] = useState(false);
  const baseCurrency = user?.branch?.base_currency ?? '';

  const handleVoucherDetailsArea = () => {
    setShowVoucherDetailArea(!showVoucherDetailArea);
  };

  const clickOnVoucher = voucher_id => {
    setVoucherId(voucher_id);
    setShowVoucherDetailArea(true);
  };

  const handleFieldChange = e => {
    let value = '';
    if (e.target.name == 'amount') {
      if (parseFloat(e.target.value) > parseFloat(dueAmount)) {
        showToast(
          'Amount should be less than or equal to balance amount',
          'error'
        );
        value = dueAmount;
      } else {
        value = e.target.value;
      }
      setFormData({
        ...formData,
        [e.target.name]: value
      });
    } else if (e.target.name == 'discount_amount') {
      if (parseFloat(e.target.value) > parseFloat(formData.amount)) {
        showToast(
          'Discount amount should be less than or equal to amount received',
          'error'
        );
        value = '0';
      } else {
        value = e.target.value;
      }
      setFormData({
        ...formData,
        [e.target.name]: value
      });
    } else {
      value = e.target.value;
      setFormData({
        ...formData,
        [e.target.name]: value
      });
    }
  };

  const handleChange = e => {
    setGivenAmount(e.target.value);
  };

  const calculateChange = () => {
    if (givenAmount === '' || givenAmount === '0') {
      setChange('');
    } else {
      let totalDiscount = formData.discount_amount
        ? formData.discount_amount
        : 0;
      let totalAmount = formData.amount - totalDiscount;
      let changeAmount = givenAmount - totalAmount;
      setChange(changeAmount);
    }
  };

  useEffect(() => {
    calculateChange();
  }, [givenAmount, formData.discount_amount, formData.amount]);

  const handleBankAccount = valueObject => {
    let bankAccountId =
      valueObject && valueObject.value ? valueObject.value : '';
    if (person.personType === 'CUSTOMER') {
      setFormData({
        ...formData,
        deposit_to: bankAccountId,
        deposit_to_ref: valueObject
      });
    } else {
      setFormData({
        ...formData,
        pay_from: bankAccountId,
        pay_from_ref: valueObject
      });
    }
  };
  const handleSubmit = e => {
    e.preventDefault();
    if (
      CheckZeroOrLessThanZeroValidator(
        formData.amount,
        'Received Amount',
        setFormError
      )
    ) {
      setFormError({});
      setSaving(true);
      if (formData.payment_mode == 'cheque') {
        if (formData.cheque_number == '') {
          setFormError({
            cheque_number: ['Cheque number is required']
          });
          setSaving(false);
        } else {
          postData();
        }
      } else {
        postData();
      }
    }
  };

  useEffect(() => {
    person.personType === 'SUPPLIER'
      ? setFormData({
          ...formData,
          pay_from: defaultBankAccount?.bank_account_id ?? '',
          pay_from_ref: {
            label: defaultBankAccount?.bank_name ?? '',
            value: defaultBankAccount?.bank_account_id ?? ''
          }
        })
      : setFormData({
          ...formData,
          deposit_to: defaultBankAccount?.bank_account_id ?? '',
          deposit_to_ref: {
            label: defaultBankAccount?.bank_name ?? '',
            value: defaultBankAccount?.bank_account_id ?? ''
          }
        });
  }, [formData.payment_mode]);

  const postData = () => {
    if (person.personType === 'SUPPLIER') {
      url = 'purchase/supplier-payments';
    } else {
      url = 'finance/customer-receipt';
    }
    axios({
      method: 'post',
      url: url,
      data: formData
    })
      .then(response => {
        if (response.data.success === true) {
          fetchData(false);
          if (person.personType === 'SUPPLIER') {
            setFormData({
              ...formData,
              amount: dueAmount - response.data.data.amount,
              discount_amount: 0,
              pay_from: '',
              pay_from_ref: '',
              payment_mode: 'cash',
              trans_ref: '',
              cheque_number: '',
              cheque_date: '',
              bank_charge: '0',
              memo: ''
            });
          } else {
            setFormData({
              ...formData,
              amount: dueAmount - response.data.data.amount,
              discount_amount: 0,
              deposit_to: '',
              deposit_to_ref: '',
              payment_mode: 'cash',
              trans_ref: '',
              cheque_number: '',
              cheque_date: '',
              bank_charge: '0',
              memo: ''
            });
          }
          if (onPaymentSuccess) {
            onPaymentSuccess(response.data.data);
          }
          setGivenAmount('');
          setChange('');
          onHide();
        } else {
          showToast(
            'Something went wrong, please refresh the page and try again.',
            'error'
          );
        }
        setSaving(false);
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : '';
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            'Something went wrong, please refresh the page and try again.',
            'error'
          );
        }
        setSaving(false);
      });
  };

  useEffect(() => {
    if (person.personType === 'SUPPLIER') {
      setFormData({
        ...formData,
        supplier_id: person.personId,
        trans_date: formData?.trans_date
          ? formData?.trans_date
          : store.currentDate,
        amount: formData.amount,
        pay_from: defaultBankAccount?.bank_account_id ?? '',
        pay_from_ref: {
          label: defaultBankAccount?.bank_name ?? '',
          value: defaultBankAccount?.bank_account_id ?? ''
        },
        discount_amount: formData.discount_amount
          ? formData.discount_amount
          : 0,
        bank_charge: formData.bank_charge ? formData.bank_charge : 0,
        allocs: [
          {
            voucher_id:
              invoiceData.trans_type == 'PV' || invoiceData.trans_type == 'RV'
                ? invoiceData.id
                : invoiceData.voucher_id,
            supplier_id: person.personId,
            trans_type: invoiceData.trans_type,
            amount: formData.amount,
            trans_date: formData.trans_date
          }
        ],
        payment_mode: formData.payment_mode ? formData.payment_mode : 'cash'
      });
    } else {
      setFormData({
        ...formData,
        customer_id: person.personId,
        trans_date: formData?.trans_date
          ? formData?.trans_date
          : store.currentDate,
        amount: formData.amount,
        deposit_to: defaultBankAccount?.bank_account_id ?? '',
        deposit_to_ref: {
          label: defaultBankAccount?.bank_name ?? '',
          value: defaultBankAccount?.bank_account_id ?? ''
        },
        discount_amount: formData.discount_amount
          ? formData.discount_amount
          : 0,
        bank_charge: formData.bank_charge ? formData.bank_charge : 0,
        allocs: [
          {
            voucher_id:
              invoiceData.trans_type == 'PV' || invoiceData.trans_type == 'RV'
                ? invoiceData.id
                : invoiceData.voucher_id,
            customer_id: person.personId,
            amount: formData.amount,
            trans_type: invoiceData.trans_type,
            trans_date: formData.trans_date
          }
        ],
        payment_mode: formData.payment_mode ? formData.payment_mode : 'cash'
      });
    }
  }, [invoiceData, formData.amount, person]);

  useEffect(() => {
    if (dueAmount) {
      setFormData(prev => ({
        ...prev,
        amount: dueAmount
      }));
    }
  }, [dueAmount]);

  const handleCancel = () => {
    if (person?.personType === 'SUPPLIER') {
      setFormData({
        ...formData,
        amount: dueAmount,
        discount_amount: 0,
        pay_from: defaultBankAccount?.bank_account_id ?? '',
        pay_from_ref: {
          label: defaultBankAccount?.bank_name ?? '',
          value: defaultBankAccount?.bank_account_id ?? ''
        },
        payment_mode: 'cash',
        trans_ref: '',
        cheque_number: '',
        bank_charge: '0',
        memo: '',
        cheque_date: ''
      });
      setGivenAmount('');
      setChange('');
      onHide();
    } else {
      setFormData({
        ...formData,
        amount: dueAmount,
        discount_amount: 0,
        deposit_to: defaultBankAccount?.bank_account_id ?? '',
        deposit_to_ref: {
          label: defaultBankAccount?.bank_name ?? '',
          value: defaultBankAccount?.bank_account_id ?? ''
        },
        payment_mode: 'cash',
        trans_ref: '',
        cheque_number: '',
        bank_charge: '0',
        memo: '',
        cheque_date: ''
      });
      setGivenAmount('');
      setChange('');
      onHide();
    }
  };

  return (
    <>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        style={{ zIndex: 9998 }}
        className="modal-with-overlay"
      >
        <Card>
          <Card.Header className="fs-1 text-dark d-flex align-items-center border-bottom">
            <div className="d-flex align-items-center justify-content-between w-100">
              <div>
                <MdOutlinePayments size={20} className={'me-2'} />
                {Translate('Payment for')}&nbsp;
                <span
                  style={{ cursor: 'pointer' }}
                  onClick={() => clickOnVoucher(invoiceData.id)}
                >
                  {invoiceData.reference}
                </span>
              </div>
              <AiOutlineClose
                size={22}
                className="text-danger cursor-pointer"
                onClick={handleCancel}
              />
            </div>
          </Card.Header>
          <Form onSubmit={handleSubmit}>
            <Card.Body className="pt-1">
              <Col md={8} sm={12} className={'m-0'}>
                <div className="p-2 ps-0 d-flex align-items-center">
                  {Translate('Total Balance Amount')}
                  &nbsp;: &nbsp;
                  <span className="fs-1 fw-bold text-warning">
                    {dueAmount
                      ? `${baseCurrency} ` +
                        acodaxNumberFormat(parseFloat(dueAmount))
                      : `${baseCurrency} 0.00`}
                  </span>
                </div>
              </Col>
              <div>
                {!Object.keys(formError).length == 0 ? (
                  <ErrorAlert
                    setFormError={setFormError}
                    formError={formError}
                  />
                ) : (
                  ''
                )}
              </div>
              <Form.Group as={Row} className="mt-2">
                <Col md={8} sm={12}>
                  <Form.Label>
                    {person.personType === 'SUPPLIER'
                      ? Translate('Supplier Name')
                      : Translate('Customer Name')}
                  </Form.Label>
                  <Form.Control disabled value={person.name} />
                </Col>
                <Col md={4} sm={12}>
                  <Form.Label>{Translate('Reference')}</Form.Label>
                  <Form.Control disabled value={invoiceData.reference} />
                </Col>
              </Form.Group>
              <Card
                className="mt-3 mb-3"
                style={{ borderLeft: '1px solid green' }}
              >
                <Card.Body>
                  <Form.Group as={Row}>
                    <Col md={4} sm={12}>
                      <Form.Label className="m-0 require-data">
                        {person?.personType === 'SUPPLIER'
                          ? Translate('Amount')
                          : Translate('Amount Received')}
                        ({baseCurrency})
                      </Form.Label>
                      <InputGroup>
                        <InputGroup.Text className="fs--1">
                          {baseCurrency}
                        </InputGroup.Text>
                        <Form.Control
                          type="number"
                          name="amount"
                          value={formData.amount}
                          onChange={handleFieldChange}
                        />
                        {formError && formError.amount ? (
                          <span className="ms-2">
                            <FormErrorPopover
                              id="amount"
                              totalErrorCount={Object.keys(formError).length}
                              errorMessage={formError.amount}
                            />
                          </span>
                        ) : (
                          ''
                        )}
                      </InputGroup>
                    </Col>
                    <Col md={4} sm={12}>
                      <Form.Label className="m-0">
                        {Translate('Discount Amount')}
                      </Form.Label>
                      <Form.Control
                        type="number"
                        min={0}
                        step="0.0000000000001"
                        name="discount_amount"
                        value={formData.discount_amount}
                        onChange={handleFieldChange}
                      />
                    </Col>
                    <Col md={4} sm={12}>
                      <Form.Label className="m-0">
                        {Translate('Payment Mode')}
                      </Form.Label>
                      <Form.Select
                        name="payment_mode"
                        value={formData.payment_mode}
                        onChange={handleFieldChange}
                        isInvalid={!!formError.payment_mode}
                      >
                        <option value="cash">{Translate('Cash')}</option>
                        <option value="card">{Translate('Card')}</option>
                        <option value="bank_transfer">
                          {Translate('Bank Transfer')}
                        </option>
                        <option value="cheque">{Translate('Cheque')}</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                </Card.Body>
              </Card>
              <Form.Group as={Row}>
                <Col md={4} sm={12} className="mb-3">
                  <Form.Label>{Translate('Transaction Ref')}</Form.Label>
                  <Form.Control
                    type="text"
                    name="trans_ref"
                    value={formData.trans_ref}
                    onChange={handleFieldChange}
                  />
                </Col>
                {formData.payment_mode === 'bank_transfer' ? (
                  <Col md={4} sm={12}>
                    <Form.Label>{Translate('Bank charges')}(If any)</Form.Label>
                    <Form.Control
                      type="number"
                      min={0}
                      step=".01"
                      name="bank_charge"
                      value={formData.bank_charge}
                      onChange={handleFieldChange}
                    />
                  </Col>
                ) : formData.payment_mode === 'cheque' ? (
                  <Col md={4} sm={12}>
                    <Form.Label className="require-data">
                      {Translate('Cheque Number')}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="cheque_number"
                      value={formData.cheque_number}
                      onChange={handleFieldChange}
                    />
                  </Col>
                ) : (
                  ''
                )}
                {person.personType === 'SUPPLIER' ? (
                  <Col md={4} sm={12}>
                    <Form.Label className="require-data">
                      {Translate('Pay From')}
                    </Form.Label>
                    <SelectBankAccount
                      value={formData.pay_from_ref}
                      error={formError.pay_from}
                      handleFieldChange={handleBankAccount}
                      label={'pay_from'}
                      labelRef={'pay_from_ref'}
                    />
                  </Col>
                ) : (
                  <>
                    <Col md={4} sm={12}>
                      <Form.Label className="require-data">
                        {Translate('Deposit To')}
                      </Form.Label>
                      <div className="d-flex w-100">
                        <SelectBankAccount
                          value={formData.deposit_to_ref}
                          error={formError.deposit_to}
                          handleFieldChange={handleBankAccount}
                          label={'deposit_to'}
                          labelRef={'deposit_to_ref'}
                        />
                        {formError && formError.deposit_to ? (
                          <FormErrorPopover
                            id="deposit_to"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.deposit_to}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </Col>
                  </>
                )}
                <Col
                  md={4}
                  sm={12}
                  className={formError.deposit_to ? 'ms-0 ps-0' : ''}
                >
                  <Form.Label className="require-data">
                    {Translate('Receipt Date')}
                  </Form.Label>
                  <div className="d-flex">
                    <AppDatePicker
                      name="trans_date"
                      value={formData.trans_date}
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      onChange={handleFieldChange}
                    />
                    {formError && formError.trans_date ? (
                      <FormErrorPopover
                        id="trans_date"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.trans_date}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </Col>
                {formData.payment_mode === 'cheque' && (
                  <Col
                    md={4}
                    sm={12}
                    className={formError.deposit_to ? 'ms-0 ps-0' : ''}
                  >
                    <Form.Label className="require-data">
                      {Translate('Cheque Date')}
                    </Form.Label>
                    <div className="d-flex">
                      <AppDatePicker
                        name="cheque_date"
                        value={formData.cheque_date}
                        yearPlaceholder="yyyy"
                        monthPlaceholder="mm"
                        dayPlaceholder="dd"
                        onChange={handleFieldChange}
                      />
                      {formError && formError.cheque_date ? (
                        <FormErrorPopover
                          id="cheque_date"
                          totalErrorCount={Object.keys(formError).length}
                          errorMessage={formError.cheque_date}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </Col>
                )}
              </Form.Group>
              <Form.Group
                as={Row}
                className="mt-3 d-flex flex-row"
                style={{
                  backgroundColor: '#EBEBEB',
                  paddingTop: '15px',
                  paddingBottom: '15px'
                }}
              >
                <Col md={4} sm={12} className="flex-2">
                  <Form.Label>{Translate('Given Amount')}</Form.Label>
                  <Form.Control
                    type="number"
                    name="given_amount"
                    value={givenAmount.given_amount}
                    onChange={handleChange}
                  />
                </Col>
                {change !== '' ? (
                  <div className="flex-1 text-center">
                    <h5 className="m-0 mt-4 text-dark fs-1">
                      {Translate('Change')}
                      &nbsp;:&nbsp;
                      <span className="text-danger fw-bold fs-1">
                        {baseCurrency}&nbsp;
                        {change.toLocaleString(navigator.language, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </span>
                    </h5>
                  </div>
                ) : (
                  ''
                )}
              </Form.Group>
              <Form.Group as={Row} className="mt-3">
                <Col sm={12}>
                  <Form.Label>{Translate('Notes')}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    name="memo"
                    value={formData.memo}
                    onChange={handleFieldChange}
                  />
                </Col>
              </Form.Group>
            </Card.Body>
            <Card.Footer className="d-flex gap-2 justify-content-end">
              <Button
                variant="danger"
                size="sm"
                disabled={saving}
                onClick={handleCancel}
              >
                {Translate('Cancel')}
              </Button>
              <Button
                variant="success"
                size="sm"
                type={'submit'}
                disabled={saving}
              >
                {!saving
                  ? Translate('Record Payment')
                  : Translate('Saving') + '...'}
              </Button>
            </Card.Footer>
          </Form>
        </Card>
      </Modal>
      <VoucherDetail
        show={showVoucherDetailArea}
        onHide={handleVoucherDetailsArea}
        setShowVoucherDetailArea={setShowVoucherDetailArea}
        type={'SI'}
        voucherId={voucherId}
        infoPage={true}
        offCanvas={true}
      />
    </>
  );
}

PaymentScreenPopup.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  invoiceData: PropTypes.any,
  fetchData: PropTypes.func,
  person: PropTypes.object,
  dueAmount: PropTypes.number,
  onPaymentSuccess: PropTypes.any
};

export default PaymentScreenPopup;
