import { React, useContext } from 'react';
import PropTypes from 'prop-types';

import { AuthWizardContext } from 'context/Context';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ShowInput from 'components/form-settings-offcanvas/ShowInput';
import useFormSettingsHook from 'hooks/useFormSettingsHook';

function SalesTableHead({
  SO,
  SD,
  SI,
  taxType,
  convertType,
  transType,
  enableCommission
}) {
  const Translate = useAxisproTranslate();
  const { settings: SI_settings } = useFormSettingsHook(transType);
  const { user } = useContext(AuthWizardContext);

  const userBranchData = user?.branch;
  const enableCustomerCommission =
    userBranchData?.sales_settings?.enable_customer_commission === '1' &&
    enableCommission
      ? true
      : false;

  const baseCurrency =
    user?.branch?.base_currency && user?.branch?.base_currency !== ''
      ? user?.branch?.base_currency
      : 'AED';

  return (
    <thead className="text-white" style={{ backgroundColor: 'black' }}>
      <tr>
        <th className="table-header-size text-center" style={{ width: '30px' }}>
          #
        </th>
        <ShowInput show={!SI_settings || SI_settings?.item_code}>
          <th
            className="table-header-size text-center"
            style={{ width: '120px' }}
          >
            {Translate('Item Code')}
          </th>
        </ShowInput>
        <th style={{ width: '350px' }} className="table-header-size">
          {Translate('Item Name')}
        </th>
        {SD || SI ? (
          <th
            style={{ width: '150px' }}
            className="table-header-size text-center"
          >
            {Translate('Batch')}
          </th>
        ) : null}
        <th
          className="table-header-size text-center"
          style={{ width: '150px' }}
        >
          {Translate(
            SO
              ? 'Ordering Qty'
              : SD
              ? 'Delivering Qty'
              : SI
              ? 'Invoicing Qty'
              : 'Quantity'
          )}
        </th>
        <ShowInput show={!SI_settings || SI_settings?.unit}>
          <th
            className="table-header-size text-center"
            style={{ width: '80px' }}
          >
            {Translate('Unit')}
          </th>
        </ShowInput>
        <th
          className="table-header-size text-center"
          style={{ width: '120px' }}
        >
          {Translate(`Price (${baseCurrency})`)}
        </th>
        {convertType === 'estimation' ? (
          <th
            className="table-header-size text-center"
            style={{ width: '120px' }}
          >
            {Translate('Margin')}
          </th>
        ) : null}
        <ShowInput show={!SI_settings || SI_settings?.unit_tax}>
          <th
            className="table-header-size text-center"
            style={{ width: '120px' }}
          >
            {taxType
              ? Translate('Tax (INC)')
              : Translate(`Tax (${baseCurrency})`)}
          </th>
        </ShowInput>
        <ShowInput show={!SI_settings || SI_settings?.discount}>
          <th
            className="table-header-size text-center"
            style={{ width: '100px' }}
          >
            {Translate('Discount')}
          </th>
        </ShowInput>
        <ShowInput
          show={
            enableCustomerCommission &&
            (!SI_settings || SI_settings?.customer_commission_percentage)
          }
        >
          <th
            className="table-header-size text-center"
            style={{ width: '140px' }}
          >
            {Translate('Commission (%)')}
          </th>
        </ShowInput>
        <th
          className="table-header-size text-center"
          style={{ width: '150px' }}
        >
          {Translate(`Total (${baseCurrency ?? 'AED'})`)}
        </th>
        <th className="table-header-size text-center" style={{ width: '70px' }}>
          {Translate('Action')}
        </th>
      </tr>
    </thead>
  );
}

SalesTableHead.propTypes = {
  SO: PropTypes.bool,
  SD: PropTypes.bool,
  SI: PropTypes.bool,
  taxType: PropTypes.bool,
  enableCommission: PropTypes.bool,
  convertType: PropTypes.string,
  transType: PropTypes.string
};

export default SalesTableHead;
