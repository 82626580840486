import axios from 'axios';
import { accountRecoveryFormKeys } from 'helpers/formKeys';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import { showToast } from 'module/Common/Toast/toast';
import React, { useState } from 'react';
import { Button, Form, InputGroup, Spinner } from 'react-bootstrap';
import { FaArrowRightLong } from 'react-icons/fa6';
import { MdEmail } from 'react-icons/md';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import MultiTenanceyList from './MultiTenanceyList';
const MultiTenancyEmailForm = () => {
  const navigate = useNavigate();
  const [validatingUser, setValidatingUser] = useState(false);
  const [compnayData, setCompanyData] = useState([]);
  const [formData, setFormData] = useState(accountRecoveryFormKeys);
  const [queryParams] = useSearchParams();
  const tenancyList = queryParams.get('tenancy_list');
  const handleFieldChange = e =>
    setFormData(generateFormDataHelper(e, null, formData));
  const handleSubmit = async e => {
    e.preventDefault();
    if (!formData.email)
      return showToast(`Invalid request, username is required.`, 'error');
    setValidatingUser(true);
    try {
      const { data } = await axios.get(
        `multi-tenants/my-tenants?email=${formData?.email}`,
        {
          headers: {
            'X-Acodax-Tenant-Id': undefined
          }
        }
      );
      if (data.success !== true)
        return showToast(
          `Something went wrong, please refresh the page and try again.`,
          'error'
        );
      setCompanyData(data?.data?.data);

      navigate(
        `?${createSearchParams({
          tenancy_list: true,
          email: formData?.email
        })}`
      );
      setValidatingUser(false);
    } catch (error) {
      setValidatingUser(false);
      if (!error.response.data || !error.response.data.message)
        return showToast(
          `Something went wrong, please refresh the page and try again.`,
          'error'
        );
      showToast(error.response.data.message, 'error');
    }
  };

  return !tenancyList ? (
    <Form onSubmit={handleSubmit} className="w-100">
      <Form.Group className="mb-3 text-center">
        <h1 className="fw-700 ">Enter Your Email</h1>
        <p className="m-0 text-muted text-center info">
          Enter your credentials to access your dashboard
        </p>
      </Form.Group>
      <Form.Group className="mb-3" controlId="u         sername">
        <InputGroup className={` rounded shadow py-1 `}>
          <InputGroup.Text className="border-0 bg-transparent px-2">
            <MdEmail size={20} />
          </InputGroup.Text>
          <Form.Control
            className="border-0"
            placeholder="Enter username or email"
            name="email"
            onChange={handleFieldChange}
            value={formData.email}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="d-flex flex-row">
        <Button
          variant="success"
          type="submit"
          size="sm"
          className="rounded w-100  d-flex flex-row justify-content-center align-items-center"
          disabled={validatingUser}
        >
          {validatingUser ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="white"
                className="me-1"
                style={{ width: '12px', height: '12px' }}
              />
              <span className="fs--1">Validating...</span>
            </>
          ) : (
            <>
              <span>Submit</span>
              <FaArrowRightLong className="ms-2 pt-1" />
            </>
          )}
        </Button>
      </Form.Group>
    </Form>
  ) : (
    <MultiTenanceyList compnayData={compnayData} />
  );
};

export default MultiTenancyEmailForm;
